import { apiClient } from "@netgame/openapi";

import type { TaskAction, TaskDetails } from "@/types";

const useGameQuest = () => {
	const { open, isOpen } = useTaoModals();
	const { gameMode } = useSwitchMode();
	const route = useRoute();
	const isGuest = useIsGuest();

	const { data: game } = useAsyncFetch({
		path: "/rest/game/{id}{mode}/",
		method: "get",
		fetchOptions: {
			path: { id: (route.query.game ?? "") as string, mode: !isGuest.value && route.query.isMoney ? "/play" : "/" }
		},
		options: {
			transform: (data) => data?.[0],
			immediate: false,
			cached: true
		}
	});

	const { data: questPageInfo, refresh: refreshQuestPageInfo } = useGetPageQuestData({ immediate: false });

	const { data: questInfo, refresh: refreshQuestInfo, isSeasonQuest } = useQuestData({ immediate: !isGuest.value });

	const questData = computed(() => questInfo.value);
	const currentTask = computed(() => questData.value?.dayInfo?.tasks?.find(({ isCompleted }) => !isCompleted));
	const questPlayerData = computed(() => questPageInfo.value?.playerData);
	const isAvailableSeasonQuest = computed(() => questData.value?.questInfo?.isAvailable);

	const isAllowedGameMode = computed(
		() =>
			(gameMode.value === "SweepStakes" && questData.value?.questInfo?.isSweepStakesModeAvailable) ||
			(gameMode.value === "TournamentPoints" && questData.value?.questInfo?.isTournamentPointsModeAvailable)
	);

	const isGameInTask = computed(() => {
		const currentTaskAction = currentTask.value?.action as TaskAction;
		const currentTaskDetails = currentTaskAction?.details as TaskDetails;

		if (currentTaskAction?.type !== "gamesList" && currentTaskAction?.type !== "game") {
			return false;
		}

		const isFoundAmongGames = !!currentTaskDetails?.games?.find((id: number) => {
			const gameId = game.value?.game?.id as number;
			return id === gameId;
		});

		const isCurrentGame = Number(currentTask.value?.action?.details?.gameId) === game.value?.game?.id;

		return isFoundAmongGames || isCurrentGame;
	});

	const isDisplayNextTaskQuest = computed(
		() =>
			questPlayerData.value?.isSubscribed &&
			currentTask.value &&
			!isOpen("LazyOModalQuestGetStarted") &&
			!isOpen("LazyOModalQuestTaskCompleted") &&
			isAllowedGameMode.value &&
			isGameInTask.value
	);

	const handleJoinQuest = async (questId: number, withoutPopup?: boolean) => {
		const { success } = await apiClient({
			path: `/rest/quest/{questId}/subscribe/`,
			method: "post",
			parameters: { path: { questId } }
		});

		if (!success) {
			return;
		}

		await refreshQuestPageInfo();
		await refreshQuestInfo();

		if (withoutPopup) {
			return;
		}

		if (isDisplayNextTaskQuest.value && questPageInfo.value?.quest!.leaderboard) {
			open("LazyOModalQuestAvailableTask");
		}
	};

	return {
		questPageInfo,
		questData,
		handleJoinQuest,
		questPlayerData,
		refreshQuestPageInfo,
		isDisplayNextTaskQuest,
		currentTask,
		isAllowedGameMode,
		isGameInTask,
		isSeasonQuest,
		isAvailableSeasonQuest,
		refreshQuestInfo
	};
};
export default useGameQuest;
